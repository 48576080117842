/*
Template Name: Minton - Admin & Dashboard Template
Version: 6.0.0
Author: CoderThemes
Email: support@coderthemes.com
File: Icons Css File
*/

// Plugins
@import "custom/plugins/icons";
